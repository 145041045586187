export const URLS = {
  baseURL: "http://api.theqxp.com/v1",
  login: "/user/login",
  register: "/user/signup",
  CustomerVerifyOTP: "/user/verify_customer",
  CompanyVerifyOTP: "/user/verify_company_email",
  resendOTP: "/user/resend_verification_code",
  forgotPassword: "/user/forgot_password",
  uploadLicense: "/user/upload_company_identity",
  uploadRegistration: "/user/upload_company_licence",
  // uploadRegistration: "/user/upload_company_identity",
  GetinTouch: "/UserActivity/add_feedback",
  uploadProfileImage: "/user/upload_profile_image",
  userprofile: "/user/get_profile",
  companyprofile: "/company/get_company_profile",
  customer_profile: "/user/get_profile",
};

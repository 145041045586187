import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authData } from "../../../helpers/stores/actions/auth";
import { fetchFromStorage, saveToStorage } from "../../../helpers/storage";
import Page from "../../../component/Page";
import OtpInput from "react-otp-input";
import { Box, Button, Typography } from "@mui/material";
import { Formik } from "formik";
import "../auth.scss";
import { ReactComponent as AuthLogoLogin } from "../../../assets/images/auth-logo.svg";
import axios from "axios";
import { URLS } from "../../../helpers/url";
import { setApiMessage } from "../../../helpers/notification";

const SigninPageView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state?.AuthDatas)?.authData;
  const auth = reduxUser ? reduxUser : fetchFromStorage("authData");

  const ResendOTP = () => {
    let user_type = new URLSearchParams(window.location.search).get("type");
    const bodyFormData = new FormData();

    bodyFormData.append("user_id", auth?.id);
    bodyFormData.append(
      "type",
      user_type
      // auth?.user_type === "user" ? "company" : "customer"
    );

    axios
      .post(URLS.baseURL + URLS.resendOTP, bodyFormData)
      .then((res) => {
        setApiMessage("success", res?.data?.message);
      })
      .catch((err) => {
        setApiMessage("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (!auth) {
      navigate("/auth/sign-in");
    }

    // eslint-disable-next-line
  }, []);
  return (
    <Page title="Verify-OTP">
      <Formik
        initialValues={{
          otp: "",
        }}
        onSubmit={(formVal) => {
          let bodyFormData = new FormData();

          bodyFormData.append("user_id", auth?.id);
          // bodyFormData.append(
          //   "type",
          //   auth?.user_type === "user" ? "customer" : "company"
          // );
          bodyFormData.append("token", formVal?.otp);
          let user_type = new URLSearchParams(window.location.search).get(
            "type"
          );
          let urlVals =
            user_type === "customer"
              ? URLS.CustomerVerifyOTP
              : URLS.CompanyVerifyOTP;
          axios
            .post(URLS.baseURL + urlVals, bodyFormData)
            .then((res) => {
              if (res.data.success === true) {
                setApiMessage("success", "User Register Successfully");
                setTimeout(() => {
                  if (user_type === "customer") {
                    navigate("/profile");
                  }
                  dispatch(
                    authData({
                      ...auth,
                      user_verified: 1,
                      user_type: user_type,
                    })
                  );
                  saveToStorage("authData", {
                    ...auth,
                    user_verified: 1,
                    user_type: user_type,
                  });
                }, 1000);
              } else {
                setApiMessage(
                  "error",
                  formVal?.otp === ""
                    ? "Enter Verification Code"
                    : res.data.message
                );
              }
            })
            .catch((err) => {
              setApiMessage("success", err?.response?.data?.message);
            });
        }}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box className="auth-wrap signnin" component={"section"}>
              <Box className="col-wrap">
                <Box className="col-left">
                  <Box className="form-wrap primary">
                    <h1 className="verify-email">Email Verification</h1>

                    <Box className="form-inner">
                      <Box className="verify-info">
                        <p className="verify-describe">
                          Enter a 4 digit verification code that has been sent
                          to your email{" "}
                          <Typography className="resend-text">
                            "{auth?.email} "
                          </Typography>{" "}
                          and to your mobile device{" "}
                          <Typography className="resend-text">
                            " {auth?.mobile} "
                          </Typography>{" "}
                          as text message.
                        </p>
                      </Box>

                      <OtpInput
                        containerStyle={{ justifyContent: "space-around" }}
                        className="enter-otp"
                        numInputs={4}
                        isInputNum
                        onChange={(e) => {
                          setFieldValue("otp", e);
                        }}
                        onBlur={handleBlur}
                        value={values.otp}
                      />

                      <Button type="submit" className="verify-btn" fullWidth>
                        Verify
                      </Button>
                      <p className="account-text">
                        Don't get the code?
                        <b>
                          <Typography
                            className="resend-text"
                            onClick={ResendOTP}
                          >
                            Resend
                          </Typography>
                        </b>
                      </p>
                    </Box>
                  </Box>
                </Box>
                <Box className="col-right">
                  <Box className="content">
                    <AuthLogoLogin />
                    <h2>
                      Helping you find <br />
                      services that you
                      <br /> need in seconds.
                    </h2>
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Page>
  );
};

export default SigninPageView;
